import React, { useState, lazy, Suspense } from 'react';
import { Router, Redirect } from '@reach/router';
import { useSelector } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';

import paths from 'constants/paths';
import retry from 'utils/retry';
import { TERMS_OF_SERVICE, PRIVACY, WARRANTY } from 'constants/contentful';

import { getCustomer } from 'reduxState/user';
import { getIsLoading } from 'reduxState/appSettings';

// Components needed for initial load. No code splitting
import ErrorBoundary from 'components/deprecated/ErrorBoundary';
import { Loading, ExternalRedirect, FedexRedirect } from 'components';
import AffirmHandlingRoutes from 'app/pages/checkout/AffirmHandlingRoutes';

import { FARMSTAND_BUNDLE_PDP_2020, NEW_FARMSTAND_BUNDLE_PDP_2021 } from 'constants/contentful';
import RecipeDetail from '../app/pages/recipeDetail';

// Lazy load / code split routes
// use retry function to resolve chunk load errors after deployments
const NotFound = lazy(() => retry(() => import('app/pages/notFound')));
const Login = lazy(() => retry(() => import('app/pages/loginNew')));
const ProductStore = lazy(() => retry(() => import('app/pages/productStore')));
const ShopPageFarmstand = lazy(() => retry(() => import('app/pages/productStore/pages/Farmstand')));
const ShopPageSeedlings = lazy(() => retry(() => import('app/pages/productStore/pages/Seedlings')));
const ShopPageSupplies = lazy(() => retry(() => import('app/pages/productStore/pages/Supplies')));
const SeedlingPackRoute = lazy(() => retry(() => import('components/deprecated/SeedlingPackRoute')));
const SeedlingsBundle = lazy(() => retry(() => import('app/pages/seedlings_bundle/SeedlingsBundle')));
const SeedlingDetail = lazy(() => retry(() => import('app/pages/seedlings/SeedlingDetail')));
const SurveyResults = lazy(() => retry(() => import('app/pages/survey-results/SurveyResults')));
const Checkout = lazy(() => retry(() => import('app/pages/checkout')));
const OrderConfirmation = lazy(() => retry(() => import('app/pages/orderConfirmation')));
const ContactUs = lazy(() => retry(() => import('app/pages/contact-us/ContactUs')));
const MyFarm = lazy(() => retry(() => import('app/pages/myFarm')));
const OrderDetails = lazy(() => retry(() => import('app/pages/order-details')));
const RecommendedOrderDetails = lazy(() => retry(() => import('app/pages/recommended-order-details')));
const FAQContainer = lazy(() => retry(() => import('app/pages/faqs/FAQContainer')));
const FAQLanding = lazy(() => retry(() => import('app/pages/faqs/FAQLanding')));
const FAQDetail = lazy(() => retry(() => import('app/pages/faqs/FAQDetail')));
const ContentfulPage = lazy(() => retry(() => import('components/deprecated/ContentfulPage')));

const PrivateRoute = lazy(() => retry(() => import('components/deprecated/PrivateRoute')));
const SurveyEmbed = lazy(() => retry(() => import('app/pages/surveyEmbed')));
const GiftCard = lazy(() => retry(() => import('app/pages/giftCard')));
const MobileShopLogic = lazy(() => retry(() => import('app/pages/mobileShopLogic')));
const MobileAuthenticator = lazy(() => retry(() => import('app/pages/moible-authenticator/MobileAuthenticator')));
const SupplyDetail = lazy(() => retry(() => import('app/pages/supply-detail/SupplyDetail')));
const FillYourFarmstand = lazy(() => retry(() => import('app/pages/fill-your-farmstand/FillYourFarmStand')));
const FillYourFarmstandBundles = lazy(() => retry(() => import('app/pages/fill-your-farmstand-bundles/FillYourFarmstandBundles')));
const GlowRings = lazy(() => retry(() => import('app/pages/glowrings-pdp/GlowRings')));
const VirtualConsultation = lazy(() => retry(() => import('app/pages/virtual-consultation/VirtualConsultation')));
const SearchResults = lazy(() => retry(() => import('app/pages/search-results/SearchResults')));
const CustomerReviews = lazy(() => retry(() => import('app/pages/customer-reviews/CustomerReviews')));
const SeedlingReview = lazy(() => retry(() => import('app/pages/seedling-review/SeedlingReview')));
const SeedlingReviewConfirmation = lazy(() => retry(() => import('app/pages/seedling-review-confirmation/SeedlingReviewConfirmation')));
const PromotionPage = lazy(() => retry(() => import('app/pages/promotion-page/PromotionPage')));
const HarvestPlanProduct = lazy(() => retry(() => import('app/pages/harvest-plan-product/HarvestPlanProduct')));
const PDFPage = lazy(() => retry(() => import('app/pages/pdf/Pdf')));
const AffirmLanding = lazy(() => retry(() => import('app/pages/affirm-landing/AffirmLanding')));
const AccessibilityStatement = lazy(() => retry(() => import('app/pages/accessibility-statement/AccessibilityStatement')));
const CareersPage = lazy(() => retry(() => import('app/pages/careers-page/CareersPage')));
const Analytics = lazy(() => retry(() => import('app/pages/analytics')));
const SubscriptionsPage = lazy(() => retry(() => import('app/pages/subscriptions/Subscriptions')));

export default function App() {
  const [sessionId] = useState(uuidv1());
  const customerId = useSelector(getCustomer).id;
  const contentCacheMap = useSelector((state) => state.contentful.cacheMap);
  const isLoading = useSelector(getIsLoading);

  if (!contentCacheMap) return null;
  return (
    <Suspense fallback={<Loading isFullHeight noImage />}>
      <ErrorBoundary>
        {isLoading && <Loading isFullHeight />}
        <Router>
          <PrivateRoute path={`${paths.MY_FARM}/*trackingNumber`} component={MyFarm} />
          <PrivateRoute path={`${paths.ORDER_DETAILS}/*orderId`} component={OrderDetails} />
          <PrivateRoute path={paths.RECOMMENDED_ORDER_DETAILS} component={RecommendedOrderDetails} />
          <PrivateRoute path={paths.PERSONALIZE_RECOMMENDATION} component={FillYourFarmstand} />
          <Analytics path={paths.ANALYTICS} />
          <Checkout path={paths.CHECKOUT} />
          <ContactUs path={paths.CONTACT_US} />
          <CustomerReviews path={`${paths.CUSTOMER_REVIEWS}`} />

          <AffirmLanding path={`${paths.AFFIRM_LANDING}`} />
          <CareersPage path={`${paths.CAREERS_PAGE}`} />

          <AccessibilityStatement path={`${paths.ACCESSIBILITY_STATEMENT}`} />

          <FAQContainer path={`${paths.FAQ}`}>
            <FAQDetail path={`/*question`} />
            <FAQLanding path={`/`} />
          </FAQContainer>
          <FillYourFarmstand path={paths.FILL_YOUR_FARMSTAND} />
          <FillYourFarmstandBundles path={paths.FILL_YOUR_FARMSTAND_BUNDLES} />
          <GiftCard path={paths.GIFT_CARD} />

          <GlowRings path={paths.shopPages.GLOW_RINGS.SIZE_2} />
          <GlowRings path={paths.shopPages.GLOW_RINGS.SIZE_3} />
          <GlowRings path={paths.shopPages.GLOW_RINGS.SIZE_4} />
          <GlowRings path={paths.shopPages.GLOW_RINGS.SIZE_5} />
          <GlowRings path={paths.shopPages.GLOW_RINGS.SIZE_6} />

          <Login path={paths.LOGIN} />
          <OrderConfirmation path={`${paths.ORDER_CONFIRMATION}/*orderId`} />
          <ProductStore path={paths.SHOP_PRODUCTS} />
          <SubscriptionsPage path={paths.shopPages.SUBSCRIPTION} />

          <ShopPageFarmstand path={paths.shopPages.FARMSTAND} />
          <ShopPageSeedlings path={paths.shopPages.SEEDLINGS} />
          <ShopPageSupplies path={paths.shopPages.SUPPLIES} />

          <PromotionPage path={paths.PROMOTION_PAGE} contentfulPage={FARMSTAND_BUNDLE_PDP_2020} />
          <PromotionPage path={paths.PROMOTION_PAGE_2} contentfulPage={NEW_FARMSTAND_BUNDLE_PDP_2021} />

          <RecipeDetail path={`${paths.RECIPES}/*slug`} />
          <SeedlingDetail path={`${paths.SEEDLINGS}/*seedlingSlug`} />
          <SupplyDetail path={`${paths.SHOP_PRODUCTS}/*supplySlug`} />

          <SeedlingPackRoute path={`${paths.SEEDLING_PACK}/*count`} />
          <SurveyResults path={paths.SURVEY_RESULTS} />
          <SurveyEmbed path={`${paths.OPEN_SURVEY}/*surveyId`} sessionId={sessionId} customerId={customerId} />
          <SurveyEmbed path={`${paths.OPEN_SURVEY}/private/*surveyId`} sessionId={sessionId} customerId={customerId} />

          <SeedlingsBundle path={`${paths.BUNDLES}/*bundleSlug`} />
          <HarvestPlanProduct path={paths.HARVEST_PLAN.ZOOEY} />
          <HarvestPlanProduct path={paths.HARVEST_PLAN.CHIPOTLE} />
          <HarvestPlanProduct path={paths.HARVEST_PLAN.MATTJAMES} />
          <HarvestPlanProduct path={paths.HARVEST_PLAN.BRIGHTLAND} />
          <HarvestPlanProduct path={paths.HARVEST_PLAN.KNOT} />
          <HarvestPlanProduct path={paths.HARVEST_PLAN.KNOT_SIZE} />
          <HarvestPlanProduct path={paths.HARVEST_PLAN.THRIVE} />
          <HarvestPlanProduct path={`${paths.HARVEST_PLAN.AMAZON}/*redemptionCode`} />

          <VirtualConsultation path={paths.VIRTUAL_SHOPPING} />

          <SearchResults path={paths.SEARCH_RESULTS} />
          <SeedlingReview path={`${paths.SEEDLING_REVIEW}/*id`} />
          <SeedlingReviewConfirmation path={`${paths.SEEDLING_REVIEW_CONFIRMATION}/*id`} />
          {/* PDF Routes */}
          <PDFPage path={paths.GROWING_GUIDE} />
          <PDFPage path={paths.ASSEMBLY_GUIDES} />
          <PDFPage path={paths.PANTRY_SHOPPING_LIST} />
          <PDFPage path={paths.CLIMATE_GUIDE} />

          {/* redirect for web app store link - /ios is IOS deep linking default to handle web */}
          <ExternalRedirect path='/ios/:context' to='https://apps.apple.com/us/app/lettuce-grow/id1452410072' />

          {/* redirect tracking links sent in emails to fedex for tracking */}
          <FedexRedirect path='/app/track/:trackingNum' to={`https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=`} />

          <ContentfulPage path={paths.TERMS} key={paths.TERMS} pageFunc={TERMS_OF_SERVICE} />
          <ContentfulPage path={paths.PRIVACY} key={paths.PRIVACY} pageFunc={PRIVACY} />
          <ContentfulPage path={paths.WARRANTY} key={paths.WARRANTY} pageFunc={WARRANTY} />

          <Redirect noThrow from='/get-started/cart' to={paths.SHOP_PRODUCTS} />
          <Redirect noThrow from='/get-started' to={paths.FARMSTAND} />
          <Redirect noThrow from='/get-started/farmstand' to={paths.FARMSTAND} />
          <Redirect noThrow from='/our-plans' to={paths.EXPERIENCE} />
          <Redirect noThrow from={`/the-journey`} to={paths.EXPERIENCE} />
          <Redirect noThrow from='/starter-packs' to={paths.EXPERIENCE} />
          <Redirect noThrow from='/cart' to={paths.CHECKOUT} />
          <Redirect noThrow from={`${paths.WHAT_WE_GROW}/*seedlingSlug`} to={`${paths.SEEDLINGS}/*seedlingSlug`} />
          <Redirect noThrow from='/indoor' to={paths.blogPosts.INDOOR_GROW_GUIDE} />
          <Redirect noThrow from={paths.blogPosts.GROWING_TIPS} to={paths.blogPosts.INDOOR_GROW_GUIDE} />
          <Redirect noThrow from={'/eatgreen'} to={paths.blogPosts.EAT_GREEN} />
          <Redirect noThrow from={'/virtual-shopping'} to={paths.VIRTUAL_SHOPPING} />
          <Redirect noThrow from={'/bundles/zooeys-bundle'} to={paths.HARVEST_PLAN.ZOOEY} />
          <Redirect noThrow from={'/bundles/brightland-harvest-bundle'} to={paths.HARVEST_PLAN.BRIGHTLAND} />
          <Redirect noThrow from={'/bundles/chipotle-harvest-bundle'} to={paths.HARVEST_PLAN.CHIPOTLE} />
          <Redirect noThrow from={'/bundles/matt-james-harvest-bundle'} to={paths.HARVEST_PLAN.MATTJAMES} />
          <Redirect noThrow from={`${paths.SEEDLINGS}/matt-james-harvest-bundle`} to={paths.HARVEST_PLAN.MATTJAMES} />

          <Redirect noThrow from={`/breakdown`} to={paths.marketing.BREAKDOWN} />
          <Redirect noThrow from={`/pakman`} to={paths.marketing.PAKMAN} />
          <Redirect noThrow from={`/beans`} to={paths.marketing.BEANS} />
          <Redirect noThrow from={`/paula`} to={paths.marketing.PAULA} />
          <Redirect noThrow from={`/nancy`} to={paths.marketing.NANCY} />
          <Redirect noThrow from={`/yo`} to={paths.marketing.YO} />
          <Redirect noThrow from={`/fakethenation`} to={paths.marketing.FAKETHENATION} />
          <Redirect noThrow from={`/whoa`} to={paths.marketing.WHOA} />
          <Redirect noThrow from={`/listen`} to={paths.marketing.LISTEN} />

          <Redirect
            noThrow
            from='/99'
            to={`${paths.EXPERIENCE}?pc=99percentpod&utm_source=99pi&utm_medium=podcast&utm_campaign=99pi_podcast`}
          />
          <Redirect noThrow from={`/blog/:postSlug`} to={`${paths.BLOG}/:postSlug`} />
          <Redirect noThrow from={`/resources/recipes`} to={paths.RECIPES} />
          <Redirect noThrow from='/shop/lg-lighting-kit' to={paths.shopPages.GLOW_RINGS.SIZE_4} />
          <Redirect noThrow from={paths.shopPages.PREORDER_GLOW_RINGS.SIZE_2} to={paths.shopPages.GLOW_RINGS.SIZE_2} />
          <Redirect noThrow from={paths.shopPages.PREORDER_GLOW_RINGS.SIZE_3} to={paths.shopPages.GLOW_RINGS.SIZE_3} />
          <Redirect noThrow from={paths.shopPages.PREORDER_GLOW_RINGS.SIZE_4} to={paths.shopPages.GLOW_RINGS.SIZE_4} />
          <Redirect noThrow from={paths.shopPages.PREORDER_GLOW_RINGS.SIZE_5} to={paths.shopPages.GLOW_RINGS.SIZE_5} />
          <Redirect noThrow from='/timer' to={paths.faqPosts.FARMSTAND_TIMER} />
          <Redirect noThrow from='/planting' to={paths.faqPosts.TRANSPLANT_SEEDLINGS} />
          <Redirect noThrow from='/ph' to={paths.faqPosts.ADJUST_PH} />
          <Redirect noThrow from='/growingguide' to={paths.blogPosts.GROWING_GUIDE} />
          <Redirect noThrow from='/reset' to={paths.faqPosts.RESET_FARMSTAND} />
          <Redirect noThrow from='/lighting' to={paths.shopPages.GLOW_RINGS.SIZE_4} />

          {/* Routes to handle Affirm success and failures from BE */}
          <AffirmHandlingRoutes path={`${paths.AFFIRM_SUCCESS}/*orderId`} />

          <MobileShopLogic path='/ios-shop' />
          <MobileAuthenticator path='/app-auth' />

          {/* ***Route*** - Default - Catch Random URLs to HOME. Should be 404 page / */}
          <NotFound path={paths.NOT_FOUND} default />
        </Router>
      </ErrorBoundary>
    </Suspense>
  );
}
